<template>
  <div class="payment-active-plan-data">
    <div class="card-area">
      <div class="card-text-cover">
        <div class="card-status-text">
          <span v-if="registeredCardList.length > 0">現在決済されるカード番号: {{ registeredCardList[0].cardNo }}</span>
          <span v-else>カード情報の登録がありません</span>
        </div>
        <el-button @click="editCard()" type="success" size="mini">カード変更</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Question from "@/components/Ui/Question.vue";

export default {
  name: "PaymentActivePlanData",
  components: {
    // Question,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "rt",
      "stockPoint",
      "userData",
      "activePlanData",
      "apiErrorMessage",
      "registeredCardList",
    ]),
  },
  created() {
    this.$store.dispatch('setRegisteredCardList');
  },
  methods: {
    editCard() {
      this.$axios
        .get(`${this.rt}/l/api/payment/get-card-editor-url`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            location.href = response.data.url;
          } else {
            alert(this.apiErrorMessage);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.card-area {
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }

  .card-text-cover {
    display: flex;
    align-items: center;

    .card-status-text {
      margin-right: 10px;
    }
  }
}
</style>
