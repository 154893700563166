<template>
  <div class="payment-active-plan-data">
    <h3 class="back-gray">カートシステム</h3>
    <div class="payment-config-cover">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>自動更新設定</span>
        </div>
        <div class="auto-update-config-cover">
          <!-- <span style="margin-right: 5px">OFF</span>
          <el-switch v-model="cartSystemAutomaticPayment"></el-switch>
          <span style="margin-left: 5px">ON</span> -->
          <div v-if="userData.useCartSystemAutomaticPayment === true" class="subscribing-cover">
            <div class="subscribing-item"></div>
            <span class="subscribing-item red">設定中</span>
            <div class="subscribing-item">
              <el-button @click="stop()" type="info" size="mini">停止</el-button>
            </div>
          </div>
          <div v-else>
            <span>未設定</span>
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>自動更新設定中のプラン名</span>
        </div>
        <div v-if="userData.useCartSystemAutomaticPayment === true">{{ activeCartSystemProductData.name }}</div>
        <div v-else>-</div>
      </el-card>
      <div class="dummy"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Question from "@/components/Ui/Question.vue";

export default {
  name: "PaymentActivePlanData",
  components: {
    // Question,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "rt",
      "stockPoint",
      "userData",
      "activePlanData",
      "apiErrorMessage",
      "registeredCardList",
    ]),
    ...mapGetters("tools02", ["activeCartSystemSubscriptionData"]),
    activeCartSystemProductData() {
      // 
      const activeCartSystemProductData = this.$store.getters['tools02/cartSystemProductData'](this.activeCartSystemSubscriptionData.cartSystemProductId);

      // 
      return activeCartSystemProductData;
    },
  },
  methods: {
    stop() {
      let message = ``;
      message += `契約中のカートシステムの自動更新を停止します。よろしいですか？`;
      if (confirm(message)) {
        this.$axios
          .post(`${this.rt}/l/api/tools02/cart-system/stop-automatic-payment`)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$store.dispatch('setUserData');
            } else {
              alert(this.apiErrorMessage);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        return;
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
