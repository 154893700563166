<template>
  <div class="Ctrl-parameter-log">
    <el-row>
      <el-col>
        <h2>決済完了</h2>
      </el-col>
    </el-row>
    <el-row v-if="toolData.codeKebab === 'cart-system'">
      <el-col>
        <p>決済が完了しました。</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="center">
          <el-button @click="$router.push('/')" type="primary">トップページへ</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
  },
  props: {
    toolId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    }
  },
  watch: {},
  created() {
    if (this.toolData.codeKebab === 'cart-system') {
      this.$store.dispatch('tools02/setCartSystemSubscriptionList');
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
p {
  text-align: center;
}
</style>
