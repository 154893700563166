<template>
  <div class="payment-active-plan-data">
    <h3 class="back-gray">ポイントプラン</h3>
    <div class="payment-config-cover">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>自動更新設定</span>
        </div>
        <div>
          <span style="margin-right: 5px">OFF</span>
          <el-switch v-model="useAutomaticPayment"></el-switch>
          <span style="margin-left: 5px">ON</span>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>自動更新設定中のプラン名</span>
        </div>
        <div v-if="useAutomaticPayment === true">{{ activePlanData[0].planName }}</div>
        <div v-else>-</div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>ポイント残高</span>
        </div>
        <div>{{ stockPoint | localeNum }}ポイント</div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Question from "@/components/Ui/Question.vue";

export default {
  name: "PaymentActivePlanData",
  components: {
    // Question,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "rt",
      "stockPoint",
      "userData",
      "activePlanData",
      "apiErrorMessage",
      "registeredCardList",
    ]),
    useAutomaticPayment: {
      get() {
        return this.userData.useAutomaticPayment;
      },
      set(useAutomaticPayment) {
        // message
        let message = ``;
        message += `ポイントプランの自動更新設定を${useAutomaticPayment === true ? 'ON' : 'OFF'}にします。よろしいですか？`;

        //
        if (confirm(message)) {
          // 
          if (useAutomaticPayment === true) {
            this.$axios
              .post(`${this.rt}/l/api/payment/check-and-create-recurring`)
              .then(response => {
                console.log(response);
                if (
                  response.data.status === 201 ||
                  response.data.status === 202
                ) {
                  // 
                  this.$store.dispatch('setActivePlanData');

                  // 
                  this.register(useAutomaticPayment);
                } else if (response.data.status === 400) {
                  alert(response.data.message);
                } else {
                  alert(this.apiErrorMessage);
                }
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            this.register(useAutomaticPayment);
          }
        } else {
          return;
        }
      },
    },
  },
  methods: {
    register(useAutomaticPayment) {
      // 
      const registerData = {
        useAutomaticPayment: useAutomaticPayment
      }
      const params = new URLSearchParams();
      params.append('register_data', JSON.stringify(registerData));
      this.$axios
        .post(`${this.rt}/l/api/user/register`, params)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch('setUserData');
          } else {
            alert(this.apiErrorMessage + "(setUserData)");
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
};
</script>

<style lang="scss" scoped></style>
