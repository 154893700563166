<template>
  <div class="tools02-home-switch-tools02-active">
    <el-row align="middle" :gutter="15">
      <el-col v-show="tool.codeKebab !== 'cart-system'">
        <span>利用設定：</span>
        <span>
          <span style="margin-right: 5px">OFF</span>
          <el-switch v-model="tools02Active"></el-switch>
          <span style="margin-left: 5px">ON</span>
        </span>
      </el-col>
      <el-col>
        <span>利用ステータス：</span>
        <span>
          <template v-if="tool.codeKebab === 'cart-system'">
            <template v-if="activeCartSystemSubscriptionData === undefined">
              <template v-if="applyingCartSystemSubscriptionData === undefined"
                ><span>利用停止中</span></template
              >
              <template v-else>
                <!-- <span
                  v-if="applyingCartSystemSubscriptionData.statusId === '7'"
                >
                  {{ applyingText }}
                </span>
                <span
                  v-else-if="
                    applyingCartSystemSubscriptionData.statusId === '5'
                  "
                  >準備中</span
                > -->
                <el-button
                  v-if="
                    applyingCartSystemSubscriptionData.statusId === '3'
                  "
                  type="primary"
                  @click="
                    startCartSystem(applyingCartSystemSubscriptionData.id)
                  "
                  v-loading="cartSystemStarting"
                  >利用開始</el-button
                >
                <span v-else>利用停止中</span>
              </template>
            </template>
            <template v-else>
              <span class="plan_name">{{
                $store.getters["tools02/cartSystemProductData"](
                  activeCartSystemSubscriptionData.cartSystemProductId
                ).name
              }}</span>
              <span v-if="activeCartSystemSubscriptionData.aspId !== '0'"
                >（{{
                  $store.getters.aspDataFromAllAspList(activeCartSystemSubscriptionData.aspId)
                    .aspName
                }}）</span
              >
              <template v-if="applyingCartSystemSubscriptionData === undefined">
                <CartSystemContinueButton
                  v-if="
                    activeContinuationCartSystemSubscriptionData === undefined
                  "
                  style="margin-left: 10px"
                />
                <span v-else>（継続準備完了）</span>
              </template>
              <template v-else>
                <span> - 継続{{ applyingText }}</span>
              </template>
            </template>
          </template>
          <template v-else>
            <span v-if="validities[tool.codeCamel] === 0"
              >利用停止中（ONにすると、今すぐ全機能をご利用できます）</span
            >
            <span v-else-if="validities[tool.codeCamel] === 1">
              <span>稼働中</span>
              <span v-if="tools02Active === true"
                >（全機能をご利用いただけます。）</span
              >
              <span v-else-if="tools02Active === false"
                >（本日23:59まで全機能をご利用できます。）</span
              >
            </span>
          </template>
        </span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CartSystemContinueButton from "@/components/Tools02/CartSystem/ContinueButton.vue";

export default {
  name: "Tools02HomeSwitchTools02Active",
  components: {
    CartSystemContinueButton,
  },
  props: {},
  data() {
    return {
      cartSystemStarting: false,
      applyingText:
        "申込中（ご入金がまだの場合は、ご入金お願いします。ご登録のメールアドレス宛にお振込み先を記載したメールを送付しています。）",
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "userData"]),
    ...mapGetters("tools02", [
      "activeCartSystemSubscriptionData",
      "applyingCartSystemSubscriptionData",
      "activeContinuationCartSystemSubscriptionData",
      "toolListInTopPageOrder",
    ]),
    ...mapGetters("subscription", ["validities"]),
    tools02Active: {
      get() {
        const toolsActive = `${this.tool.codeCamel}Active`;
        return this.$store.getters.userData[toolsActive];
      },
      set(newToolConfig) {
        //
        const OnlySquadbeyondCvOn =
          this.checkOnlySquadbeyondCvOn(newToolConfig);

        //
        const squadbeyondToolData = this.$store.getters.toolData("16");

        // message
        let squadbeyondErrorMessage = "";
        let message = "";
        if (newToolConfig === false) {
          //
          if (OnlySquadbeyondCvOn === false) {
            //
          } else {
            squadbeyondErrorMessage = `${squadbeyondToolData.name}のみONにすることはできません。先に${squadbeyondToolData.name}をOFFにしてください。`;
            alert(squadbeyondErrorMessage);
            return;
          }
          message = `利用ステータスをOFFにします。よろしいですか？（本日は引き続き利用でき、翌日0時から利用できなくなります。）`;
        } else if (newToolConfig === true) {
          //
          if (OnlySquadbeyondCvOn === false) {
            //
          } else {
            squadbeyondErrorMessage = `${squadbeyondToolData.name}のみONにすることはできません。先に他のツールをONにしてください。`;
            alert(squadbeyondErrorMessage);
            return;
          }

          //
          const toolValidity = this.validities[this.tool.codeCamel];
          const toolPoint = this.tools[this.tool.codeCamel].point;

          //
          if (toolValidity === 0) {
            message = `利用設定をOFFからONにすると、即時に${toolPoint}ポイント消化され、今すぐに全機能がご利用いただけます。よろしいですか？`;
          } else if (toolValidity === 1) {
            message = `利用設定をOFFからONにすると、すでに${toolPoint}ポイント消化されているため、0ポイントで今すぐに全機能がご利用いただけます。よろしいですか？`;
          } else {
            alert("validitiesが不正な値で来ています。");
            return;
          }
        } else {
          alert("tools02Activeが不正な値で来ています。");
          return;
        }

        //
        if (confirm(message)) {
          this.$store
            .dispatch("subscription/setSubscriptionActive", {
              toolId: this.tools[this.tool.codeCamel].id,
              active: newToolConfig,
            })
            .then((res) => {
              console.log(res);
              if (res.data.status === 200) {
                this.$store.dispatch("setUserData", null, {
                  root: true,
                });
                this.$store.dispatch("setStockPoint", null, {
                  root: true,
                });
                this.$store.dispatch("subscription/setValidities");
                this.$store.dispatch("tools02/setSubscriptionList");
                return;
              } else if (res.data.status === 400 || res.data.status === 500) {
                alert(res.data.message);
                return;
              } else {
                alert(this.apiErrorMessage);
                return;
              }
            })
            .catch((err) => {
              console.log(err);
              alert(this.apiErrorMessage);
              return;
            });
        } else {
          return;
        }
      },
    },
  },
  watch: {},
  methods: {
    checkOnlySquadbeyondCvOn(newToolConfig) {
      // config一覧の一時データを作る
      const toolConfigData = {};
      for (const toolData of this.toolListInTopPageOrder) {
        const toolCodeCamel = toolData.code2Camel;
        toolConfigData[toolCodeCamel] = this.userData[`${toolCodeCamel}Active`];
      }
      console.log(toolConfigData);

      // 該当ツールを指定の設定にしてみる
      toolConfigData[this.tool.codeCamel] = newToolConfig;
      console.log(toolConfigData);

      // SBのみONになるかどうかチェック
      const configTrueCount = Object.values(toolConfigData).filter(
        (toolConfig) => toolConfig === true
      ).length;
      const onlySquadbeyondCvOn =
        configTrueCount === 1 && toolConfigData.squadbeyondCv === true;

      //
      return onlySquadbeyondCvOn;
    },
    startCartSystem(cartSystemSubscriptionId) {
      if (confirm("カートシステムの利用を開始します。よろしいですか？")) {
        this.cartSystemStarting = true;

        //
        let params = new URLSearchParams();
        params.append("cart_system_subscription_id", cartSystemSubscriptionId);
        this.$axios
          .post(`${this.rt}/l/api/tools02/cart-system/start`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$store.dispatch("setUserData");
              this.$store.dispatch("tools02/setCartSystemSubscriptionList");
              alert(response.data.message);
            } else if (response.data.status === 400) {
              alert(response.data.message);
            } else if (response.data.status === 401) {
              alert(response.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
            }
            this.cartSystemStarting = false;
          })
          .catch(function (error) {
            console.log(error);
            this.cartSystemStarting = false;
          });
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin: 0;
}
.button {
  text-align: center;
  .el-button {
    border-radius: 50px;
    font-size: 17px;
    background-color: #f5f5f5;
  }
}
</style>
